<template>
    <div id="pageTable">      
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="false"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                rounded
                                color="#004BAF"
                                class="btnAdd"
                                right
                                @click="abrirModal('add')"
                                >
                                <v-icon dark>add</v-icon>Agregar
                                </v-btn>
                            </div>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>{{item.nombre}}</td>
                                <td>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ tituloModal }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text>
                                <div id="padre" v-show="isLoading">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoading" grid-list-md id="contenedor">
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Nómina" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Nómina"
                                                    :items="itemsNominas"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="nomina_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Empleado"
                                                    :items="itemsEmpleados"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="empleado_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Concepto" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Concepto"
                                                    :items="itemsConcepto"
                                                    item-text="descripcion"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="concepto_id"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" 
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-layout row>
                                    <v-flex class="justify-center">
                                        <div class="btnModal">
                                            <v-btn class="btnGuardar modal-pull-right" @click="guardar()" :disabled="invalid" :loading="isSaving">Guardar</v-btn>
                                            <button class="btnCerrar modal-pull-right" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Brigadas';
import Notify from '@/plugins/notify';
import apiBrigadas from '@/api/brigadas';


export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            brigada           : {
                id                      : null,
                nombre                  : null
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isLoading       : false,
            isSaving        : false,
            imagen          : '',
            itemsNominas    : [],
            nomina_id       : null,
            itemsEmpleados  : [],
            empleado_id     : null,
            itemsConcepto   : [],
            concepto_id     : null,
        }
    },
    methods: {
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.$apollo.queries.brigadas;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoading  = true;
            this.imagen     = "/static/icon/catalogos.png"


            if(accion == "add"){
                this.tituloModal = "Captura de nómina";
                
            } else {
                this.tituloModal = "Actualizar nómina";
            }
            this.isLoading  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.brigada      = {
                id                      : null,
                nombre                  : null
            }
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        eliminar(data){
            /* Notify.Alert(
                "¡Hey! ¿Estás seguro?",
                "¿Estás seguro que deseas eliminar la brigada " + data.nombre + "?",
                "SI! QUIERO ELIMINARLO",
                () => {
                    apiBrigadas.delete(data.id).then(response => {
                        Notify.Success("Brigada eliminada", "La Brigada ha sido eliminada satisfactoriamente");
                        this.$apollo.queries.brigadas.refetch();
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    });
                }
            ) */
        },
        guardar() {
            /* this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                if(this.accion === "add") {
                    apiBrigadas.store(this.brigada)
                    .then((response) => {
                        Notify.Success("Excelente!", "La brigada se guardó satisfactoriamente.");
                        this.$apollo.queries.brigadas.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
                else if(this.accion === "update") {
                    apiBrigadas.update(this.brigada)
                    .then((response) => {
                        Notify.Success("Excelente!", "La brigada se actualizó satisfactoriamente.");
                        this.$apollo.queries.brigadas.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                        this.isSaving = false;
                    });
                }
            }); */
        },
    },
    apollo: {
        brigadas: {
            query       : queries.brigadasTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : 'nombre',
                    Order       : 'ASC'
                }
            },
            result({loading, error}) {
                this.loading    = false;
                this.tableData  = this.brigadas;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

</style>
